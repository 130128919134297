/* eslint-disable */

/**
 * 该文件为脚本自动生成文件，请勿随意修改。如需修改请联系 PMC
 * */

import { TdConfigProviderProps } from './type';
import { PropType } from 'vue';

export default {
  /** 全局配置 */
  globalConfig: {
    type: Object as PropType<TdConfigProviderProps['globalConfig']>,
  },
};
