/* eslint-disable */

/**
 * 该文件为脚本自动生成文件，请勿随意修改。如需修改请联系 PMC
 * */

export default {
  /** 顶栏高度。样式表（class）中定义的默认高度为：64px */
  height: {
    type: String,
    default: '',
  },
};
